import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Colors } from 'common-constants/colors'
import { ratingPath } from 'components/page/RatingWave/Rating.paths'
import { searchPath } from 'components/page/Search/Search.paths'
import {
  NavigationPlace,
  blogPath,
} from 'components/presentational/Navigation/Navigation.constants'
import { MenuItem } from 'components/presentational/Navigation/Navigation.types'
import { RatingActiveSvg } from 'components/presentational/svg/RatingActiveSvg'
import { RatingDefaultSvg } from 'components/presentational/svg/RatingDefaultSvg'
import { RatingHoverSvg } from 'components/presentational/svg/RatingHoverSvg'
import { RatingSvg } from 'components/presentational/svg/RatingSvg'
import { Search2ActiveSvg } from 'components/presentational/svg/Search2ActiveSvg'
import { SearchDefaultSvg } from 'components/presentational/svg/SearchDefaultSvg'
import { SearchHoverSvg } from 'components/presentational/svg/SearchHoverSvg'
import { SearchSvg } from 'components/presentational/svg/SearchSvg'

import { BlogActiveSvg } from '../svg/BlogActiveSvg'
import { BlogHoverSvg } from '../svg/BlogHoverSvg'
import { BlogNormalSvg } from '../svg/BlogNormalSvg'

/**
 * TODO Вынести jsx элементы из констант.
 */
export const NAVIGATION_LINKS: Record<NavigationPlace, MenuItem> = {
  search: {
    to: searchPath,
    selectedPaths: [searchPath],
    renderIcon: (color: Colors) => <SearchSvg stroke={color} />,
    label: <FormattedMessage id="app.search" defaultMessage="Поиск" />,
    iconDefault: <SearchDefaultSvg />,
    iconHover: <SearchHoverSvg />,
    iconActive: <Search2ActiveSvg />,
    ['data-name']: 'link-main-menu__search-action',
    isExternalLink: false,
  },
  rating: {
    to: ratingPath,
    selectedPaths: [ratingPath],
    renderIcon: (color: Colors) => <RatingSvg color={color} />,
    label: <FormattedMessage id="app.rating" defaultMessage="Знакомства" />,
    iconDefault: <RatingDefaultSvg />,
    iconHover: <RatingHoverSvg />,
    iconActive: <RatingActiveSvg />,
    ['data-name']: 'link-main-menu__rating-action',
    isExternalLink: false,
  },
  blog: {
    to: blogPath,
    label: <FormattedMessage id="app.journal" defaultMessage="Журнал" />,
    iconDefault: <BlogNormalSvg />,
    iconHover: <BlogHoverSvg />,
    iconActive: <BlogActiveSvg />,
    ['data-name']: 'link-main-menu__blog-action',
    isExternalLink: true,
  },
}
